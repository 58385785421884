
import {
  defineComponent,
  Ref,
  ref,
  watch,
  WritableComputedRef,
} from "@vue/composition-api";
import { computedAsync, useVModel } from "@vueuse/core";

export default defineComponent({
  props: {
    value: {
      type: String,
      required: false,
      default: undefined,
    }
  },
  setup(props, { emit }) {
    const evaluating = ref(false);

    const model:
      | Ref<string | undefined>
      | WritableComputedRef<string | undefined> = useVModel(
      props,
      "value",
      emit,
      { eventName: 'input' }
    );

    const input = ref<HTMLInputElement>();

    const file = ref<File>();

    const onInput = () => {
      file.value = undefined;

      if (input.value?.files) file.value = input.value?.files[0];
    };

    const image = computedAsync(
      () =>
        new Promise<string | undefined>((resolve) => {
          if (file.value) {
            const reader = new FileReader();

            reader.onload = (event) => {
              const img = event.target?.result as string;
              if (event.target?.result) {
                const el = new Image();
                el.onload = () =>
                  emit("resize", { width: el.width, height: el.height });
                el.src = img;
                setTimeout(() => resolve(img), 1000);
              } else {
                resolve(model.value);
              }
            };

            reader.readAsDataURL(file.value);
          } else {
            resolve(model.value);
          }
        }),
      undefined,
      { lazy: true, evaluating }
    );

    watch(image, (img) => {
      model.value = img;
    });

    const remove = () => {
      model.value = undefined;
      file.value = undefined;
      if (input.value) input.value.value = "";
    };

    return { evaluating, model, input, file, onInput, image, remove };
  },
});
